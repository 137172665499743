import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 34;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>NYのウィンターマーケット</li>
        <li>年末ですね</li>
        <li>クラブハウスが今どうなってるか</li>
        <li>蜂に刺されたトラウマ</li>
        <li>箕澤屋で蜂にやられたおじさん</li>
        <li>オオスズメバチの退治をしたときの話</li>
        <li>思い出話は老害でしかない</li>
        <li>今年最高の出来だったハチ公物語</li>
        <li>動画を撮ることで始まった年</li>
        <li>動画はとにかくパワーが必要</li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
